import React, { FC, useCallback } from 'react'
import { PageProps, navigate } from 'gatsby'
import { Router } from '@reach/router'

import withMemo from '../../decorators/withMemo'
import { Layout } from '../../components'
import { PictureTemplate } from '../../templates'
import routes from '../../router/routes'

const PictureContainer: FC<PageProps> = () => {
  const handleOnRestartClick = useCallback(() => {
    navigate(routes.root.path)
  }, [])

  return (
    <Layout>
      <Router basepath="/picture">
        <PictureTemplate onRestartClick={handleOnRestartClick} path="/" withoutSlug />
        <PictureTemplate onRestartClick={handleOnRestartClick} path="/:slug" />
      </Router>
    </Layout>
  )
}

export default withMemo(PictureContainer)
